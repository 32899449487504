import * as React from "react"
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { Link } from "gatsby"


import './nav.css'

const drawerWidth = 240;

function Nav({ location }) {
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }} className="nav-drawer">
            <Typography variant="h6" sx={{ my: 2, display: "flex", alignItems: "center", justifyContent: "center" }} className="logo">
                <span>The Food API</span>
                <img src='/icons/icon-large.png' width={56} height={24} alt="The Food Api logo" style={{ marginLeft: "1rem" }} />
            </Typography>
            <Divider />
            <List>
                <ListItem key={"Home"} disablePadding className={(location.pathname === "/" ? "selected" : "")}>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={<Link to="/">Home</Link>} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"API"} disablePadding className={(location.pathname === "/api" ? "selected" : "")}>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={<Link to="/api">API</Link>} />
                    </ListItemButton>
                </ListItem>
                <ListItem key={"Roadmap"} disablePadding className={(location.pathname === "/roadmap" ? "selected" : "")}>
                    <ListItemButton sx={{ textAlign: 'center' }}>
                        <ListItemText primary={<Link to="/roadmap">Roadmap</Link>} />
                    </ListItemButton>
                </ListItem>
            </List>
        </Box>
    );

    const container = typeof window !== "undefined" ? () => window.document.body : undefined;

    return (
        <>
            <AppBar component="nav" style={{ background: "#007788" }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'none', sm: 'flex' } }}
                        style={{ alignItems: "center" }}
                    >
                        <Link to='/' style={{ color: "white", textDecoration: "none", display: "flex", alignItems: "center" }} className="logo">
                            <div>
                                <div>The Food API</div>
                                <div style={{
                                    position: "absolute",
                                    fontFamily: "Roboto",
                                    bottom: "0",
                                    fontSize: "80%",
                                    color: "#ff8300"
                                }}><b>in Beta</b></div>
                            </div>
                            <img src='/icons/icon-large.png' width={56} height={24} alt="The ,Food Api logo" style={{ marginLeft: "1rem" }} />
                        </Link>
                        <div style={{ display: "flex", marginLeft: "auto", marginRight: "2rem" }}>
                            <Link className={(location.pathname === "/" ? "selected" : "")} to='/' style={{ color: "white", textDecoration: "none", marginLeft: "auto", marginRight: "3rem" }} ><span>Home</span></Link>
                            <Link className={(location.pathname === "/api" ? "selected" : "")} to='/api' style={{ color: "white", textDecoration: "none", marginRight: "3rem" }} ><span>API</span></Link>
                            <Link className={(location.pathname === "/pricing" ? "selected" : "")} to='/pricing' style={{ color: "white", textDecoration: "none", marginRight: "3rem" }} ><span>Pricing</span></Link>
                            <Link className={(location.pathname === "/roadmap" ? "selected" : "")} to='/roadmap' style={{ color: "white", textDecoration: "none" }} ><span>Roadmap</span></Link>
                        </div>
                        <div style={{ marginleft: "auto" }}>
                            <Link
                                style={{
                                    background: "white",
                                    color: "#007788",
                                    padding: "0.5rem 1rem",
                                    borderRadius: "4px",
                                    textDecoration: "none"
                                }}
                                to='/signup'>Sign up</Link>
                        </div>
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{
                display: { xs: 'block', sm: 'none' },
            }}>
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    location={location}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
                <div style={{
                    position: "fixed",
                    right: "0.5rem",
                    top: "0.5rem",
                    fontFamily: "Roboto Serif",
                    background: "rgb(0, 119, 136)",
                    border: "2px solid rgb(0, 119, 136)",
                    borderRadius: "8px",
                    overflow: "hidden",
                    padding: "6px 12px",
                    background: "white",
                    color: "rgb(0, 119, 136)",
                    zIndex: "1110",
                }}>
                    <span>in BETA</span>
                </div>
            </Box>
        </>
    )
}

export default Nav
