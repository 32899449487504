import * as React from "react"
import PropTypes from "prop-types"
import '@fontsource/roboto/400.css';
import Nav from './nav'

import "./layout.css"

const Layout = ({ location, children }) => {

  return (
    <>
      <div>
        <Nav location={location} />
        <main style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
          {children}
          <div style={{
            padding: "1rem",
            textAlign: "center",
            color: "#00778875",
            marginTop: "auto"
          }}>
            The Food API 2023 ©
          </div>
          <div style={{
            textAlign: "center",
            marginBottom: "1rem"
          }}>
            <a
              style={{
                color: "#00778875"
              }}
              href="https://www.privacypolicygenerator.info/live.php?token=fziOD65GhSRDzONVqJcFxdCxZYBSMwDc" target="_blank" alt="Privace policy">Privacy policy</a></div>
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
